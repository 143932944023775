.fixedWrapper {
  width: 100vw;
  max-width: 1600px;
  position: relative;
  isolation: isolate;
}

.scrollingWrapper {
  width: 100%;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}

.contentSwitcherWrapper {
  position: absolute;
  top: var(--ds-spacing-3xs);
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: fit-content;

  @media (min-width: 1200px) {
    top: var(--ds-spacing-sm);
  }

  & > * {
    overflow: auto;
  }
}

.overflowWrapper {
  display: grid;
  width: calc(var(--tab-count) * 100vw);
  max-width: calc(var(--tab-count) * 1600px);
  grid-template-columns: repeat(var(--tab-count), 1fr);
}

.itemWrapper {
  scroll-snap-align: start;
}
